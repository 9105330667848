import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91item_93GHVunr73gyMeta } from "/vercel/path0/pages/events/[item].vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as meeting_45form1qyTmK65OgMeta } from "/vercel/path0/pages/meeting-form.vue?macro=true";
import { default as _91item_93Z6s9hH0QMEMeta } from "/vercel/path0/pages/meetingpackages/[item].vue?macro=true";
import { default as indexdbYcUSRZncMeta } from "/vercel/path0/pages/meetingpackages/index.vue?macro=true";
import { default as meetingroomsePjiPPEGcdMeta } from "/vercel/path0/pages/meetingrooms.vue?macro=true";
import { default as _91item_936E40VvcyMaMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as restaurantJB34tLIxmjMeta } from "/vercel/path0/pages/restaurant.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "events-item___nl",
    path: "/evenementen/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___en",
    path: "/en/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___de",
    path: "/de/veranstaltungen/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events___nl",
    path: "/evenementen",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/veranstaltungen",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "faq___nl",
    path: "/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/haufig-gestellte-fragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___nl",
    path: "/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights___nl",
    path: "/fine-tips",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___en",
    path: "/en/fine-tips",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___de",
    path: "/de/fine-tips",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "meeting-form___nl",
    path: "/vergaderformulier",
    component: () => import("/vercel/path0/pages/meeting-form.vue")
  },
  {
    name: "meeting-form___en",
    path: "/en/meeting-form",
    component: () => import("/vercel/path0/pages/meeting-form.vue")
  },
  {
    name: "meeting-form___de",
    path: "/de/besprechungsformular",
    component: () => import("/vercel/path0/pages/meeting-form.vue")
  },
  {
    name: "meetingpackages-item___nl",
    path: "/vergaderarrangementen/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___en",
    path: "/en/meeting-packages/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___de",
    path: "/de/tagungspakete/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages___nl",
    path: "/vergaderarrangementen",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___en",
    path: "/en/meeting-packages",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___de",
    path: "/de/tagungspakete",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingrooms___nl",
    path: "/vergaderzalen",
    component: () => import("/vercel/path0/pages/meetingrooms.vue")
  },
  {
    name: "meetingrooms___en",
    path: "/en/meeting-rooms",
    component: () => import("/vercel/path0/pages/meetingrooms.vue")
  },
  {
    name: "meetingrooms___de",
    path: "/de/tagungsraume",
    component: () => import("/vercel/path0/pages/meetingrooms.vue")
  },
  {
    name: "news-item___nl",
    path: "/blog/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___en",
    path: "/en/blog/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___de",
    path: "/de/blog/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news___nl",
    path: "/blog",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/blog",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/blog",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "packages-item___nl",
    path: "/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___nl",
    path: "/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "privacy___nl",
    path: "/privacybeleid",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/datenschutz",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "restaurant___nl",
    path: "/brasserie",
    component: () => import("/vercel/path0/pages/restaurant.vue")
  },
  {
    name: "restaurant___en",
    path: "/en/restaurant",
    component: () => import("/vercel/path0/pages/restaurant.vue")
  },
  {
    name: "restaurant___de",
    path: "/de/restaurant",
    component: () => import("/vercel/path0/pages/restaurant.vue")
  },
  {
    name: "reviews___nl",
    path: "/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "rooms___nl",
    path: "/kamers",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___en",
    path: "/en/rooms",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___de",
    path: "/de/zimmer",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]